import { useTrackAIGenerationStatusQuery } from "@/content/ai/hooks/__generated__/useTrackAIGenerationStatusQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import useInterval from "@utils/hook/useInterval"
import { useCallback } from "react"
import { graphql } from "relay-runtime"

type Props = {
  entityId: GlobalID
}

function getReferenceName(ref: {
  url: string | null
  embeddingSource?: {
    content?: {
      name: string | null
    } | null
    asset?: {
      name: string | null
    } | null
  } | null
  uploadedAsset?: {
    name: string | null
  } | null
}) {
  return ref.url
    ? ref.url
    : ref.embeddingSource?.content?.name ||
        ref.embeddingSource?.asset?.name ||
        ref.uploadedAsset?.name ||
        "Unknown"
}

function useTrackAIGenerationStatus(props: Props) {
  const { entityId } = props
  const { aiCurriculumGenerationV2 } = useFeatureFlags()

  const [{ curriculum }, refetch] =
    Relay.useRefetchableQuery<useTrackAIGenerationStatusQuery>(
      graphql`
        query useTrackAIGenerationStatusQuery($curriculumId: ID!) {
          curriculum: node(id: $curriculumId) {
            ... on Curriculum {
              aiGeneration {
                id
                status
                acceptedMessageId
                references {
                  edges {
                    node {
                      id
                      status
                      url
                      summary
                      embeddingSource {
                        asset {
                          name
                        }
                        content {
                          name
                        }
                      }
                      uploadedAsset {
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      { curriculumId: entityId },
      { refetchInBackground: true }
    )
  const aiGeneration = curriculum?.aiGeneration
  const status = aiGeneration?.status
  const isGenerationInProgress = status === "running" || status === "pending"

  const refetchStatus = useCallback(() => {
    refetch({ curriculumId: entityId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId])

  useInterval(refetchStatus, 5000, { shouldStartInterval: isGenerationInProgress })

  const references = Relay.connectionToArray(aiGeneration?.references).map((ref) => ({
    id: ref.id,
    status: ref.status,
    name: getReferenceName(ref),
  }))

  const acceptedMessageId = aiGeneration?.acceptedMessageId
  const isOnDraftStep = !acceptedMessageId && aiCurriculumGenerationV2
  const isGeneratingDraft = isGenerationInProgress && isOnDraftStep
  const isGeneratingEntity = isGenerationInProgress && !isOnDraftStep

  const hasIncompleteGeneration =
    status === "pending" ||
    status === "running" ||
    status === "draft" ||
    // If it failed on the draft step, we consider it incomplete so they can reopen the
    // canvas and choose an older version or make adjustments
    (status === "failed" && aiCurriculumGenerationV2)

  // Show the canvas if we haven't started the last generation step yet
  const showAICanvas = hasIncompleteGeneration && !isGeneratingEntity

  return {
    status,
    aiGenerationId: aiGeneration?.id,
    refetchStatus,
    references,
    hasIncompleteGeneration,
    isGenerationInProgress,
    acceptedMessageId,
    showAICanvas,
    isGeneratingDraft,
    isGeneratingEntity,
  }
}

export default useTrackAIGenerationStatus
